import FontFaceObserver from "fontfaceobserver";

const font = new FontFaceObserver("Lato Bold", {
    weight: 700
})

font.load().then(function(){
    let storyHeading = document.querySelector("h1.main__title")
    if (storyHeading) {
        storyHeading.classList.add("font-loaded");
    }
    /**homepage lede story hed **/
    let cardHeading = document.querySelectorAll(".card__title")
    if (cardHeading) {
        cardHeading.forEach(function(heading){
            heading.classList.add('font-loaded')
        })
    }
})


const subheadFont = new FontFaceObserver("Lato", {
    weight: 400
})

subheadFont.load().then(function(){
    let storySubheading = document.querySelector("p.main__subtitle")
    if (storySubheading) {
        storySubheading.classList.add("font-loaded")
    }
    let storyTease = document.querySelectorAll("p.card__tease")
    if (storyTease) {
        storyTease.forEach(function(teaseGraph){
            teaseGraph.classList.add('font-loaded')
        })
    }
    let imageCaption = document.querySelector("figure.article__lead-photo figcaption")
    if (imageCaption) {
        imageCaption.classList.add("font-loaded")
    }
})

const storyBodyFont = new FontFaceObserver("PT Serif", {
    weight: 400
})

storyBodyFont.load().then(function(){
    let storyBodyText = document.querySelectorAll(".article__body p")
    if (storyBodyText) {
        storyBodyText.forEach(function (graph) {
            graph.classList.add("font-loaded")
        })
    }
})



