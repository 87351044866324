// Toggle header nav menu
$(".header__nav__menu-button").click(function () {
  $(this).attr("aria-expanded", function (i, attr) {
    if (attr === "true") {
      // Closing the menu

      // Unhide the rest of the page content
      $(".header").siblings().removeClass("hidden");

      return "false";
    } else {
      // Opening the menu

      // Set the menu's 'top' css property based on the height of the header banner
      $(".header__nav__menu").css("top", function () {
        return ($(".breaking-bar").outerHeight() ?? 0) + $(".header").outerHeight();
      });

      // Make sure user controls menu is closed
      $(".header__user-controls__menu-button").attr("aria-expanded", "false");

      // Hide the rest of the page content
      $(".header").siblings().addClass("hidden");

      return "true";
    }
  });
});

// Toggle header user controls menu (mobile)
$(".header__user-controls__menu-button").click(function () {
  $(this).attr("aria-expanded", function (i, attr) {
    if (attr === "true") {
      // Closing the menu

      // Unhide the rest of the page content
      $(".header").nextAll().removeClass("hidden");

      return "false";
    } else {
      // Opening the menu

      // Set the menu's 'top' css property based on the height of the header banner
      $(".header__user-controls__menu").css("top", function () {
        return ($(".breaking-bar").outerHeight() ?? 0) + $(".header").outerHeight();
      });

      // Make sure user controls menu is closed
      $(".header__nav__menu-button").attr("aria-expanded", "false");

      // Hide the rest of the page content
      $(".header").nextAll().addClass("hidden");

      return "true";
    }
  });
});
